<template>
  <div class="reg-intro">
      <div class="ui two column stackable centered grid">
          <div class="column">

              <h3 class="text-danger text-center">A lire avant de commencer.</h3>
              <p>👋 Bienvenue,</p> 
              <p>Ce processus d'inscription vous prendra en moyenne 3 à 5 minutes.</p>
      <p>Assurez-vous d'avoir les documents ci-dessous à portée de main avant de commencer:</p>
      <p class="text-bold" style="margin-bottom : 3em">
          ✔️ Votre pièce d'identité (CNI ou Passeport)<br>
          ✔️ Votre permis de conduire<br>
          ✔️ La carte grise de votre véhicule<br>
      </p>


        <sui-button size="big" fluid primary @click="startRegistration" >
                JE SUIS PRÊT A COMMENCER <i class="arrow right icon"></i>
        </sui-button>

        <sui-button size="big" fluid secondary @click="continueRegistration" style="margin-top : 10px">
                POURSUIVRE MON INSCRIPTION
        </sui-button>
          </div>
      </div>

      
  </div>
</template>

<script>
export default {
    name:'regIntroduction',
    methods: {
        startRegistration(){
            this.$router.push({ path:'/register/account' });
        },
        continueRegistration(){
            this.$router.push({ path:'/login' });
        }
    },

}
</script>

<style>

</style>