<template>
    <div>

     <div class="header">
        <a href='https://wabeyi.net'><img id="logo" src="../../assets/images/logo_wabeyi.png" /> </a>
      </div>


        <div class="banner">
          <h1>Devenez conducteur Wabeyi</h1>
          <h3>Augmentez vos revenus tout en maîtrisant votre emploi du temps</h3>
        </div>

        <sui-container>
          <router-view></router-view>
        </sui-container>
        
    </div>
</template>

<script>
export default {
    name: 'register',
    metaInfo: {
      title: 'Inscription',
    },
    data() {
        return {
            
        }
    },
    mounted() {
    },
}
</script>

<style lang="scss" scoped>

</style>