import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import SuiVue from 'semantic-ui-vue';
import 'semantic-ui-css/semantic.min.css'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMeta from 'vue-meta'

Vue.use(SuiVue);
Vue.use(VueSweetalert2);
Vue.use(VueMeta)

// Vue Config
Vue.config.productionTip = false

// Start Vue App
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
