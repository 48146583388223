import Vue from 'vue'
import VueRouter from 'vue-router'
import RegAccount from '../views/register/account.vue'
import RegCar from '../views/register/car.vue'
import RegComplete from '../views/register/complete.vue'
import RegInfos from '../views/register/infos.vue'
import Register from '../views/register/index.vue'
import Home from '../views/home.vue'
import Account from "../views/account/account.vue";
import Login from "../views/login.vue";
import ResetPassword from "../views/resetpassword.vue";
import RegIntroduction from '../views/register/introduction';

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'home',
    component: Home
  },
  {
    path: '/account',
    name: 'driverAccount',
    component: Account,
    beforeEnter: (to, from, next) => {
      if (!localStorage.userId) {
        next({
          path: '/login'
        });
      } else {
        next();
      }
    }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    children: [
      {
        path: '',
        name: 'regIntroduction',
        component: RegIntroduction,
        beforeEnter: (to, from, next) => {
          localStorage.clear();
          next();
        }
      },
      {
        path: 'account',
        name: 'account',
        component: RegAccount,
        beforeEnter: (to, from, next) => {
          localStorage.clear();
          next();
        }
      },
      {
        path: 'infos',
        name: 'infos',
        component: RegInfos,
        beforeEnter: (to, from, next) => {
          if (!localStorage.userId) {
            next({
              path: '/register'
            });
          } else {
            next();
          }
        }
      },
      {
        path: 'car',
        name: 'car',
        component: RegCar,
        beforeEnter: (to, from, next) => {
          if (!localStorage.userId) {
            next({
              path: '/register'
            });
          } else {
            next();
          }
        }
      },
      {
        path: 'complete',
        name: 'complete',
        component: RegComplete,
        beforeEnter: (to, from, next) => {
          if (!localStorage.userId && !localStorage.cardId) {
            next({
              path: '/register'
            });
          } else {
            next();
          }
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: ResetPassword
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
