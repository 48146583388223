<template>
  <div class="account">

        <div class="ui three column stackable centered grid">
          <div class="column">
            <!-- {{user}} -->
            <StepBar :total="3" :current="1" />

            <sui-form @submit="this.submit">
              <sui-form-field>
                <label>Nom de famille *</label>
                <sui-input autocomplete="--wb-no-fill" required v-model="user.surname" type="text" placeholder="Nom de famille"/><br>
              </sui-form-field>

              <sui-form-field>
                <label>Prénoms *</label>
                <sui-input autocomplete="--wb-no-fill" required v-model="user.firstname" type="text" placeholder="Prénoms"/><br>
              </sui-form-field>

              <sui-form-field>
                <label>E-mail *</label>
                <sui-input autocomplete="--wb-no-fill" required v-model="user.email" type="email" placeholder="E-mail"/><br>
              </sui-form-field>

              <sui-form-field>
                  <label>Genre *</label>
                  <select required class="ui selection dropdown" v-model="user.gender">
                    <option value="M">Homme</option>
                    <option value="F">Femme</option>
                  </select>
              </sui-form-field>

                <!-- <sui-form-field>
                  <label>Type de conducteur *</label>
                  <select required class="ui selection dropdown" v-model="user.is_fulltime">
                    <option value="true">Plein temps</option>
                    <option value="false">Mi Temps</option>
                  </select>
                </sui-form-field> -->

                <sui-form-field>
                  <label>Pays *</label>
                  <select required class="ui selection dropdown" v-model="user.country">
                    <option value="">Selectionner</option>
                    <option v-for="pays in ListePays" :key="pays.id" :value="pays.value">{{pays.value}}</option>
                  </select>
                </sui-form-field> 

                <sui-form-field>
                  <label>Ville *</label>
                  <sui-input autocomplete="--wb-no-fill" required v-model="user.city" type="text" placeholder="Ville"/><br>
                </sui-form-field> 

              <sui-form-field>
                  <label>Téléphone *</label>
                  <sui-input autocomplete="--wb-no-fill" required v-model="user.phone" type="text" pattern="[0-9]{10,}" placeholder="Téléphone"/><br>
                </sui-form-field>       


              <sui-form-field>
                <label>Créer un mot de passe *</label>
                <sui-input required v-model="user.password" type="password" placeholder="Créer un mot de passe"/><br>
              </sui-form-field>

              <sui-form-field>
                <label>Confirmer le mot de passe *</label>
                <sui-input required v-model="user.confirmpassword" type="password" placeholder="Créer un mot de passe"/><br>
              </sui-form-field>
        

              <sui-form-field>
                    <label class="wb_checkbox"> 
                      <input type="checkbox" required v-model="user.termsAccepted"> J'ai lu et j'accepte <a target="wbTerms" :href="this.$store.state.config.TermsURL"> les conditions d'utilisation</a>
                    </label>
              </sui-form-field>  
            
              <sui-button size="big" fluid primary type="submit">
                SUIVANT <i class="arrow right icon"></i>
              </sui-button>
                
              <!-- <p>Vous disposez déja d'un compte ?<a href="/login"> Se connecter</a></p> -->


            </sui-form>

          </div> 
                       
        </div>

    </div>

</template>

<script>
// import Steps from "../components/steps";
import service from "../../providers/service";
import StepBar from "../../components/StepBar";

export default {
  name: 'regAccount',
  components: {
    StepBar
  },
  data(){
    return{
      user:{ 
        
       },
      ListePays : null,
      ListePiece : null,
    }
  },
  methods:{
     getListePays () {
      service.getLists('country')
      .then( res => { this.ListePays = res.data; })
    },
    getListePiece () {
      service.getLists('idtype')
      .then( res => { this.ListePiece = res.data; })
    },

    checkForm(){
      if(this.user.password !== this.user.confirmpassword){
         this.$swal({ 
              icon:'error', 
              text: 'Les mots de passe ne sont pas identiques' 
          });
          this.user.password = this.user.confirmpassword = "";
        return false;
      }else 
      if(!this.user.password.match(/(?=^.{8,}$)(?=.*?[#@$?]).*$/)){
        this.$swal({ 
            icon:'error', 
            html: "Votre mot de passe ne respecte pas les conditions nécessaires : <br> <b>Minimum 8 caratères et contenir au moins l'un de ces caractères spéciaux : #@$? </b>"
        });
        return false;
      }else{
        return true;
      }
    },
    submit(e){
      e.preventDefault();

      if(this.checkForm() == true) {
      this.$store.state.loadingActive = true;

          this.user.phone = this.user.phone.length == 10 ? '00225' + this.user.phone : this.user.phone;
          this.user.username = this.user.phone;

          service.createCustomer(this.user)
          .then( res => {

              if( res.status == 200 ){
                service.loginCustomer({ "email": this.user.email, "password": this.user.password})
                .then( res => {
                  this.$store.state.loadingActive = false;
                  
                  if(res.status == 200){
                    localStorage.userId = res.data.userId;
                    localStorage.userAccessToken = res.data.id;
                    this.$router.push({ path: '/register/infos' })
                  }
                })
                .catch( err => {
                  this.$store.state.loadingActive = false;
                  console.log(err);
                })
              }    
          })
          .catch( err => {
            this.$store.state.loadingActive = false;
            const errors = err.response.data.error.details.codes;

            if(errors.email && errors.email.indexOf("uniqueness") == 0){
              this.$swal({ 
                icon:'info', 
                text: 'Vous avez déja un compte conducteur avec cette adresse email. Veuillez vous connecter à votre compte.' })
                .then(() => {
                this.$router.push({ path:'/account' });
              });
            }else if(errors.username && errors.username.indexOf("uniqueness") == 0){
              this.$swal({ 
                icon:'info', 
                text: "Vous avez déja un compte conducteur avec ce numéro de téléphone. Veuillez vous connecter à votre compte." })
                .then(() => {
                this.$router.push({ path:'/account' });
              });
            }else{
              this.$swal({ 
                icon:'error', 
                text: "Une erreur s'est produite. Veuillez vérifier les informations renseignées." })
                .then(() => {
                // this.$router.push({ path:'/account' });
              });
            }
          });
      }

    }

  },
  mounted(){
    this.getListePays();
    this.getListePiece();
  }
}
</script>

<style lang="scss" scoped>

</style>