<template>
    <div class="files">
          <sui-segment>
            <sui-dimmer :active="loading" inverted>
            <sui-loader size="small">Veuillez patienter</sui-loader>
            </sui-dimmer>

           <a :href="fileUrl" target="imagePreview">
                <sui-image v-if="fileUrl" class="uploadedImage"
                    :src="fileUrl  + '?' + Date.now()"
                    rounded
                    centered
                />  
            </a> 
            
            <label :for="'file-upload-' + this.id" class="custom-file-upload">
                <i class="icon upload"></i> 
                <span v-if="fileUrl">Remplacer l'image</span> 
                <span v-else>Selectionner une image</span> 
            </label>
            <input hidden accept="image/*" type="file" @change="onFileSelected" name="" :id="'file-upload-' + this.id">

            <p v-if="this.error" class="text-danger" style="margin-top : 5px">Une erreur s'est produite. Vérifier votre connexion Internet puis réessayez</p>
            
        </sui-segment>


    </div>
</template>

<script>
import core from "../providers/core";
import imageCompression from 'browser-image-compression';

export default {
    name:'fileInput',
    props : [
        'id',
        'type', 
        'name'
    ],
    data() {
        return {
            selectedFile : null,
            fileUrl : null,
            loading : false,
            error : false
        }
    },
    methods:{
        onFileSelected(event){
            this.error = false;
        
            if(event.target.files.length > 0){
                this.fileUrl = null;
                this.selectedFile = event.target.files[0];
                this.uploadPicture(this.selectedFile);
            }
            
        },
        uploadPicture : async function (){
            this.loading = true;
            const CompressOptions = { 
                    maxSizeMB: 1
            }

            try {
                try {
                    var compressedFile = await imageCompression(this.selectedFile, CompressOptions);
                } catch (error) {
                     compressedFile = this.selectedFile;
                }
                var result = await core.uploadFileToS3(compressedFile, this.name, this.type);
                const link = result.Location;
                this.fileUrl = link;
                this.$emit('input', link);
                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.error = true;
                console.log(error)
            }
            
        }
        
    }

}
</script>

<style lang="scss" scoped>
.custom-file-upload{
    width: 100%;
    background:rgba(0,0,0,.05);
    display: block;
    padding: 1em;
    text-align: center;
    border-radius: .3em .3em 0 0;
    cursor: pointer;

    &:hover{
         background:rgba(0,0,0,.1);   
    }
}
.uploadedImage {
    height: 200px;
    text-align: center;
    margin-bottom: 1em;
    object-fit: cover
}
</style>