<template>
    <select :required="this.required" class="ui selection dropdown" v-on:input="$emit('input', $event.target.value)">
        <option value="">Selectionner</option>
        <option v-for="y in Years" :key="y.$index">{{y}}</option>
    </select>
</template>

<script>
    export default {
        name: 'YearSelect',
        data() {
            return {
                Years : []
            }
        },
        props : {
            required : {
                default : null
            },
            min : {
                default : new Date().getFullYear() - 35
            },
            max : {
                default : new Date().getFullYear()
            }
        },
        mounted() {
            for (let i = this.max; i >= this.min; i--) {
                this.Years.push(i);
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>