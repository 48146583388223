const { join, extname } = require('path');
const AWS = require('aws-sdk');
const s3 = new AWS.S3({
    secretAccessKey : "xn2zpqYTuAl1sImJaw1AzPY41YZQ+err1Dquz5fg",
    accessKeyId : "AKIA3PTZJ2SCG64XYJHT"
});

/**
 * Helper method which takes the request object and returns a promise with the AWS S3 object details.
 */
const uploadFileToS3 = (file, name, type, options = {}) => {  
  
  try {
     // File extension
  var fileExtension = `.${file.type.split('/')[1]}`;
  // generate a new random file name
  var fileName = name ? `${name}.${String(Date.now())}` : options.name || String(Date.now());
  fileName = fileName + fileExtension;
  console.log(fileName);
  const fileContentType = file.type;
  const folderName = type ? type : 'others';
  
  // the extension of your file
  const extension = extname(file.path);
  
  // return a promise
  return new Promise((resolve, reject) => {
    return s3.upload({
      Bucket: 'wabeyi',
      Body: file, 
      ContentType: `${fileContentType}` , 
      ACL: 'public-read',
      Key: join(folderName, `${fileName}${extension}`),
    }, (err, result) => {
      if (err) {
        reject(err);
      }
      else resolve(result); // return the values of the successful AWS S3 request
    });

    
  });
  } catch (error) {
    throw error;
  }
 
  
};

module.exports = { uploadFileToS3 }