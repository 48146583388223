import axios from "axios";
const ApiRoot = process.env.VUE_APP_API_ROOT;

var svc = {};

svc.createUserInfo = function (userId, data) {
    return axios.post(ApiRoot + 'drivers/' + userId + '/info?access_token=' + localStorage.userAccessToken, data)
}

svc.createUserCar = function (userId, data) {
    return axios.post(ApiRoot + 'drivers/' + userId + '/cars?access_token=' + localStorage.userAccessToken, data)
}

svc.completeRegistation = function (userId, data) {
    return axios.post(ApiRoot + 'drivers/' + userId + '/registrationcomplete?access_token=' + localStorage.userAccessToken, data)
}


svc.loginCustomer = function (data) {
    return axios.post(ApiRoot + 'drivers/login', data)
}

svc.logoutCustomer = function () {
    return axios.post(ApiRoot + 'drivers/logout?access_token=' + localStorage.userAccessToken)
}

svc.getUserData = function (userId) {
    const filter = { "include": ["info"] };
    return axios.get(ApiRoot + 'drivers/' + userId + '?filter=' + JSON.stringify(filter) + '&access_token=' + localStorage.userAccessToken);
}

svc.getLists = function (type) {
    return axios.get(ApiRoot + 'lists?filter[where][type]=' + type)
}

svc.createCustomer = function (data) {
    return axios.post(ApiRoot + 'drivers', data);
}

svc.updateDriverData = function (userId, data) {
    return axios.patch(
        ApiRoot + `drivers/${userId}` + '?access_token=' + localStorage.userAccessToken, data
    )
}

svc.updateDriverInfoData = function (userId, data) {
    return axios.put(
        ApiRoot + `drivers/${userId}/info` + '?access_token=' + localStorage.userAccessToken, data
    )
}

svc.updateDriverCarData = function (userId, carId, data) {
    return axios.put(
        ApiRoot + `drivers/${userId}/cars/${carId}` + '?access_token=' + localStorage.userAccessToken, data
    )
}

svc.requestResetpassword = function (email) {
    return axios.post(
        ApiRoot + `drivers/requestpasswordreset`,
        {
            "email": email
        }
    )
}

svc.resetpassword = function (email, token, newPassword) {
    return axios.post(
        ApiRoot + `drivers/resetpassword`,
        {
            "email": email,
            "token": token,
            "newPassword": newPassword
        }
    )
}



export default svc;