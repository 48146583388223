<template>
  <div>
    <div class="header">
      <a href='/'><img id="logo" src="../assets/images/logo_wabeyi.png" /> </a>
    </div>

    <div class="log">
      <div class="ui three column centered grid stackable">
        <div class="column">
          <h2>Connexion</h2>
          <sui-form @submit="this.login">
            <sui-form-field>
              <label>Adresse e-mail ou numéro de téléphone</label>
              <sui-input required v-model="user.email" autocomplete="username" type="email"
                placeholder="Adresse E-mail ou numéro de téléphone" /><br>
            </sui-form-field>
            <sui-form-field>
              <label>Mot de passe</label>
              <sui-input required v-model="user.password" autocomplete="current-password" type="password"
                placeholder="Mot de passe" /><br>
            </sui-form-field>

            <sui-form-field>
              <sui-button type="submit" fluid size="big" primary>SE CONNECTER</sui-button>
            </sui-form-field>

            <sui-form-field>
              <p>Vous avez oublié votre mot de passe? <a href="/resetpassword">Réinitialiser</a></p>
              <p>Vous n'avez pas de compte ? <a href="https://app.wabeyi.net/go">Inscrivez-vous</a></p>
            </sui-form-field>
          </sui-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import service from "../providers/service";

export default {
  data() {
    return {
      user: { ttl: 60 * 60 * 2 }
    }
  },
  metaInfo: {
    title: 'Connexion',
  },
  methods: {
    login(e) {

      e.preventDefault();

      this.user.email = this.user.email.replace(/ /g, '');

      this.$store.commit('showLoading', true);

      service.loginCustomer(this.user).then(res => {

        if (res.status == 200) {

          this.$store.commit('showLoading', false);

          localStorage.userId = res.data.userId;
          localStorage.userAccessToken = res.data.id;
          this.$router.push({ path: '/account' });

        }
      })
        .catch(err => {
          this.$store.commit('showLoading', false);
          this.$swal({
            icon: 'error',
            html: `Erreur ${err.response.data.error.statusCode} <br> ${err.response.data.error.message}`
          });
        })

    }
  },
  render() {
    return null
  },

}
</script>

<style lang="scss" scoped>
// le bleu du logo rgba(63, 169, 245, 1)

.log {
  margin-top: 3em;
}
</style>
