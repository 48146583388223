import Vue from 'vue'
import Vuex from 'vuex'
import config from "../providers/config";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId: null,
    loadingActive : false,
    config : config
  },
  mutations: {
    showLoading(state, value){
      state.loadingActive = value
    }
  },

  actions: {},

  getters:{},

  modules: {}
})
