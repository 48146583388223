<template>
  <div>
    <div class="header">
      <a href='/'><img id="logo" src="../assets/images/logo_wabeyi.png" /> </a>
    </div>

    <div class="log">
      <div class="ui three column centered grid stackable">
        <div v-if="!requestpasswordResetSuccess" class="column">
          <h2>Mot de passe oublié</h2>
          <sui-form @submit="this.requestPasswordReset">
            <sui-form-field>
              <label>Entrer votre adresse e-mail</label>
              <sui-input required v-model="user.email" autocomplete="username" type="email"
                placeholder="Adresse E-mail" /><br>
            </sui-form-field>

            <sui-form-field>
              <sui-button type="submit" fluid size="big" primary>SOUMETTRE</sui-button>
            </sui-form-field>
          </sui-form>
        </div>
        <div v-if="requestpasswordResetSuccess" class="column">
          <h2>Confirmation</h2>
          <p>Un code de vérification vous a été envoyé par mail.</p>
          <sui-form @submit="this.passwordReset">
            <sui-form-field>
              <label>Code de vérification</label>
              <sui-input required v-model="user.token" autocomplete="token" type="text"
                placeholder="Code de vérification" /><br>
            </sui-form-field>

            <sui-form-field>
              <label>Entrer votre nouveau mot de passe</label>
              <sui-input required v-model="user.newPassword" autocomplete="newPassword" type="password"
                placeholder="Nouveau mot de passe" /><br>
            </sui-form-field>

            <sui-form-field>
              <sui-button type="submit" fluid size="big" primary>REINITIALISER</sui-button>
            </sui-form-field>
          </sui-form>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import service from "../providers/service";

export default {
  data() {
    return {
      user: {},
      requestpasswordResetSuccess: undefined,
    }
  },
  metaInfo: {
    title: 'Connexion',
  },
  methods: {
    requestPasswordReset(e) {
      e.preventDefault();
      this.user.email = this.user.email.replace(/ /g, '');
      this.$store.commit('showLoading', true);

      service.requestResetpassword(this.user.email).then(res => {
        if (res.status == 200) {
          this.$store.commit('showLoading', false);
          this.requestpasswordResetSuccess = true;
        }
      })
        .catch(err => {
          this.$store.commit('showLoading', false);
          this.$swal({
            icon: 'error',
            html: `Erreur ${err.response.data.error.statusCode} <br> ${err.response.data.error.message}`
          });
        })
    },
    passwordReset(e) {
      e.preventDefault();
      this.$store.commit('showLoading', true);

      if (!this.user.newPassword.match(/(?=^.{8,}$)(?=.*?[#@$?]).*$/)) {
        this.$store.commit('showLoading', false);
        this.$swal({
          icon: 'error',
          html: "Votre mot de passe ne respecte pas les conditions nécessaires : <br> <b>Minimum 8 caratères et contenir au moins l'un de ces caractères spéciaux : #@$? </b>"
        });
      } else {
        service.resetpassword(this.user.email, this.user.token, this.user.newPassword).then(res => {
          if (res.status == 200) {
            this.$store.commit('showLoading', false);
            this.$swal({
              icon: 'success',
              html: `Votre mot de passe a été modifié avec succès!`
            });
            this.$router.push({ path: '/login' });
          }
        })
          .catch(err => {
            this.$store.commit('showLoading', false);
            this.$swal({
              icon: 'error',
              html: `Erreur ${err.response.data.error.statusCode} <br> ${err.response.data.error.message}`
            });
          })
      }

    },
  },
  render() {
    return null
  },

}
</script>

<style lang="scss" scoped>
// le bleu du logo rgba(63, 169, 245, 1)

.log {
  margin-top: 3em;
}
</style>
