<template>
  <div id="app">

  <sui-segment basic id="loadingSegment">

    <sui-dimmer :active="this.$store.state.loadingActive">
      <sui-loader>Veuilez patienter...</sui-loader>
    </sui-dimmer>

    <router-view />

    <footer v-if="this.$route.name !== 'login'">
      <sui-container>
        <sui-grid>
          <sui-grid-row>
            <sui-grid-column :width="12">
              <p>© Design by GLADUX 2023</p>

            <sui-list bulleted :class="this.footerListAlignment">
                <a target="_blank" href="https://www.wabeyi.net/politique-de-confidentialite" is="sui-list-item">Politique de confidentialité</a>
                <a target="_blank" href="https://www.wabeyi.net/conditions-utilisation" is="sui-list-item">Conditions d’utilisation</a>
                <a target="_blank" href="https://www.wabeyi.net/mentions-legales" is="sui-list-item">Mentions légales</a>
            </sui-list>

            </sui-grid-column>
            <sui-grid-column :width="4" style="text-align: right">
              <a href="https://wabeyi.net"> <img src="./assets/images/icon_wabeyi.png" alt="" srcset=""></a>
            </sui-grid-column>
          </sui-grid-row>
        </sui-grid>

      </sui-container>
    </footer>
    
  </sui-segment>

  </div>
</template>

<script>

export default {
  name:"app",
  data() {
      return {
        footerListAlignment : 'horizontal',
      }
  },
  metaInfo: {
    title: 'Bienvenue',
    titleTemplate: '%s - Conducteurs Wabeyi',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: "Wabeyi Drivers, espace pour les conducteurs Wabeyi. Plateforme de covoiturage. Soyez remuneré en aidant quelqu'un à se deplacer"
      },
      { name: 'twitter:image', content: 'https://drivers.wabeyi.net/home_banner_bg.png'},
      { name: 'og:image', content: 'https://drivers.wabeyi.net/home_banner_bg.png' },
      { property: 'og:image', content: 'https://drivers.wabeyi.net/home_banner_bg.png'}

    ] 
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      if(window.innerWidth < 600){
        this.footerListAlignment = null
      }else{
        this.footerListAlignment = 'horizontal'   
      }
    }
  }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Work+Sans:wght@900&display=swap');

a,h2,h3,h4,h5,h6,p,div,li,.btn,input, .button{
  font-family: 'DM Sans' !important;
}
h1{
  font-family: 'Work Sans' !important;
  // font-weight: 900;
}

.ui.selection.dropdown{
  padding: .5em 2.1em .5em 1em !important
}
#app{
  text-align: center;
  height: 100%;
}

#loadingSegment{
  padding: 0;
  min-height: 100%;
}
.header {
  padding: 2rem 0;

    #logo{
      width: 10rem;
    }
    
}

.center{
  text-align: center
}

.section{
  padding: 4em 0;

  .title{
    margin-bottom: 2em;
    font-size: 2.3em;
  }
}

.text-bold{
  font-weight: bold;
}

.cta-link{
  font-size: 1.5rem;
  display: block;
  margin: 2em 0;
  &:hover{
    cursor: pointer;
  }
}

.banner{
    background-color:rgba(122, 201, 67, 1);;
    padding: 40px;
    margin-bottom: 2rem;
    h1{
      margin-bottom:0rem;
      color: white;
    }
    h3{
      margin-top:0rem;
      color: rgba(255, 255, 255, 0.77);
    }
}

.text-danger{
  color: red;
}

.text-center{
  text-align: center !important;
}

footer{
  margin-top: 5em;
  text-align: left;
  padding-top: 2em;
  background: #f2f2f2;

  p{
    margin-bottom: 0;
    color: #888;
    font-size: 11px !important;
  }

  a {
    font-size: 11px !important;
    color: #555 !important;
  }

  img{
    width: 3rem;
    filter: grayscale(1);
  }

}

label.wb_checkbox{
  &:hover{
    cursor: pointer;
  }
  input[type=checkbox]{
    vertical-align: unset !important;
    width: 15px;
    height: 15px;
  }
}

#accountDetails .avatar img{
  width: 10rem !important;
  height: 10rem !important;
}

@media screen and (max-width: 600px){

  .list{
    .item{
      display: list-item !important;
      table-layout: fixed;
      list-style-type: none;
      list-style-position: outside;
      padding: .21428571em 0;
      line-height: 1.14285714em;
    }
  }

  .banner{
      padding: 1.5em 2em;
      h1{
        font-size: 1.5em;
      }
      h3{
        font-size: 1em;
      }
  }

}

</style>
