<template>
    <div>

        <Header />

        <div id="banner">

            <div class="overlay">

                <sui-container>

                    <sui-grid stackable>
                        <sui-grid-row>
                            <sui-grid-column :width="10">
                                <div class="home-text">
                                    <h1>
                                        Soyez rémunéré en aidant quelqu'un à se déplacer
                                    </h1>
                                </div>
                            </sui-grid-column>
                            <sui-grid-column :width="6">
                                <div class="home-actions">
                                    <a href="https://app.wabeyi.net/go" class="action register">
                                        <h2> S'inscrire</h2>
                                        <p>Devenir conducteur</p>
                                    </a>
                                    <a href="/login" class="action account">
                                        <h2> Mon compte</h2>
                                        <p>Accéder à votre compte</p>
                                    </a>
                                </div>
                            </sui-grid-column>
                        </sui-grid-row>
                    </sui-grid>

                </sui-container>

            </div>

        </div>

        <div class="section" id="advantages">

            <sui-container>

                <h2 class="title">Les avantages d’être conducteur Wabeyi</h2>

                <sui-grid stackable>
                    <sui-grid-row :columns="3">
                        <sui-grid-column>
                            <div class="adv">
                                <img src="../assets/images/money.png">
                                <h3>Vous êtes payé <br> immediatement</h3>
                                <p>Les personnes que vous transportez vous payent au cash.</p>
                            </div>
                        </sui-grid-column>
                        <sui-grid-column>
                            <div class="adv">
                                <img src="../assets/images/team.png">
                                <h3>Vous faites de nouvelles rencontres</h3>
                                <p>Chaque voyage est une occasion de rencontrer de nouvelles personnes.</p>
                            </div>
                        </sui-grid-column>
                        <sui-grid-column>
                            <div class="adv">
                                <img src="../assets/images/time.png">
                                <h3>Vous ne changez rien à votre emploi du temps</h3>
                                <p>Vous transportez les passagers selon votre emploi du temps habituel.</p>
                            </div>
                        </sui-grid-column>
                    </sui-grid-row>
                </sui-grid>

                <div class="cta">
                    <a href="https://app.wabeyi.net/go" class="cta-link">
                        Je m’inscris
                        <i class="chevron right icon"></i>
                    </a>
                </div>

            </sui-container>


        </div>

    </div>
</template>

<script>
import Header from "../components/Header.vue";

export default {
    name: 'home',
    components: {
        Header,
    },
    mounted() {
    },
}
</script>

<style lang="scss" scoped>
#banner {

    background-image: url('../assets/images/home_banner_bg.png');
    background-size: cover;
    background-position: center 30%;


    .overlay {
        padding: 10em 0;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.4);

        .home-text {
            padding: 4em 3em 1em 1em;
            text-align: left;

            h1 {
                font-size: 3em;
                color: #FFF;
            }
        }

        .home-actions {
            .action {
                display: block;
                padding: 2em 4em;
                border-radius: 5em;
                color: #FFF;

                &:not(:last-child) {
                    margin-bottom: 1em;
                }

                h2 {
                    margin-bottom: 0
                }

                p {
                    color: rgba($color: #FFF, $alpha: 0.8)
                }

                &.register {
                    background: #12A75C;

                    &:hover {
                        background: rgb(18, 194, 106);
                        cursor: pointer;
                    }
                }

                &.account {
                    background: #0A71D9;

                    &:hover {
                        background: rgb(18, 134, 250);
                    }
                }

            }
        }
    }

}

#advantages {
    .adv {
        padding: 2em;
        text-align: left;
        background: #e4f3ff;

        h3 {
            font-size: 1.5rem;
        }

        p {
            color: #333
        }

        img {
            width: 5rem;
        }
    }
}

@media screen and (max-width: 600px) {

    .home-actions {
        .action {
            padding: 1em 2em !important;
        }
    }

    #banner .overlay .home-text {
        padding: 0em 0em 1em 0em;
        text-align: center;

        h1 {
            font-size: 2em;
        }
    }

    #advantages .adv {
        text-align: center;
    }
}
</style>