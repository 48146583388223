<template>
  <div class="complete">
          <div class="ui two column stackable centered grid">
            <div class="column">
              
              <div class="congratulation-message">

                  <h3>Votre inscription a été prise en compte. <br><br> Votre compte est en attente de validation. <br> Vous receverez un mail de confirmation dans un délai de 72h. <br> Merci d'avoir choisi Wabeyi.</h3>
                  
                  <img src="../../assets/images/registration_complete.png"/>

              </div>

              <a href="/" class="ui button primary">
                <i class="arrow left icon"></i>
                Retour à l'acceuil
              </a>

            </div>
          </div>
  </div>
</template>

<script>
import service from "../../providers/service";
export default {
  name : "complete",
  mounted() {
    service
    .completeRegistation(localStorage.userId)
    .then((res) => {

      if(res.code == 200){
          service.logoutCustomer()
          .then( () => {
                localStorage.clear();
          })
      }

    })

  },

}
</script>

<style lang="scss" scoped>

.complete{
  .button{
    display: block;
    margin-top: 1em
  }
}

.congratulation-message{
  text-align: center;
  background: rgba($color: #2353bb, $alpha: .02);
  padding: 2em 4em;
  border-radius: em;

  img{
    width: 20rem;
  }

}

@media only screen and (max-width: 600px){
 .congratulation-message{
  
  padding: 2em;

  img{
    width: 100% !important;
  } 

 }

}

</style>