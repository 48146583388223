<template>
    <div>
        <div id="header">
            <sui-menu secondary>
                <sui-container>
                    <a is="sui-menu-item" href="/">
                        <img id="logo" src="../assets/images/logo_wabeyi.png" /> <br>
                    </a>
                    <sui-menu-item id="driver-space">
                        <h4>Espace conducteur</h4>
                    </sui-menu-item>

                    <sui-menu-menu position="right">
                        <sui-menu-item id="social">
                              <a href="https://web.facebook.com/wabeyi" target="_blank" is='sui-button' circular primary icon="facebook f" />
                        </sui-menu-item>
                    </sui-menu-menu>
                </sui-container>
            </sui-menu>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'AppHeader'
        
    }
</script>

<style lang="scss" scoped>


#header{
    // border-bottom: 1px #dedede solid;

    #logo{
        width: 100px;
    }
    .menu {
        margin-right: 0;
        #driver-space{
            padding-left: 0;
        }
        #social{
            padding: 0;
        }
    }
}


</style>