<template>
  <div class="infos">

    <div class="ui three column stackable centered grid">
      <div class="column">

        <StepBar :total="3" :current="2" />

        <!-- {{info}} -->

        <sui-form @submit="this.submit" stackable>

          <!-- <sui-form-fields fields="three"> -->
          <sui-form-field>
            <label>Type de pièce *</label>
            <select required class="ui selection dropdown" v-model="info.id_type">
              <option value="">Selectionner</option>
              <option v-for="piece in ListePiece" :key="piece.id">{{ piece.value }}</option>
            </select>
          </sui-form-field>

          <sui-form-field>
            <label>Numéro de pièce *</label>
            <sui-input required v-model="info.id_number" type="text" placeholder="Numéro de pièce" /><br>
          </sui-form-field>

          <!-- <sui-form-field>
                    <label>Photo de profil *</label>
                      <fileInput 
                      id="photo"
                      v-model="info.photo" 
                      :name="this.info.userId"
                      type="avatar" />  
                  </sui-form-field>    -->
          <!-- </sui-form-fields> -->

          <!-- <sui-form-fields fields="three"> -->
          <sui-form-field>
            <label>Numéro de permis de conduire *</label>
            <input required v-model="info.driver_driving_licence_id" placeholder="Numéro de permis de conduire" />
          </sui-form-field>

          <sui-form-field>
            <label>Photo du permis de conduire (recto) *</label>
            <fileInput id="driving_licence_front" v-model="info.driver_driving_licence_front" :name="this.info.userId"
              type="docs" />
          </sui-form-field>

          <sui-form-field>
            <label>Photo du permis de conduire (verso) *</label>
            <fileInput id="driving_licence_back" v-model="info.driver_driving_licence_back" :name="this.info.userId"
              type="docs" />
          </sui-form-field>

          <!-- </sui-form-fields> -->


          <sui-button v-if="!this.noNextPage" size="big" fluid primary type="submit">
            SUIVANT <i class="arrow right icon"></i>
          </sui-button>
          <sui-button v-if="this.noNextPage" size="big" fluid primary type="submit">
            SAUVEGARDER
          </sui-button>

        </sui-form>
      </div>
    </div>
  </div>
</template>

<script>
import service from "../../providers/service";
import fileInput from "../../components/fileInput"
import StepBar from "../../components/StepBar";

export default {
  name: 'infos',
  props: {
    noTitle: {
      type: Boolean
    },
    noNextPage: {
      type: Boolean
    }
  },
  components: {
    fileInput,
    StepBar
  },
  data() {
    return {
      info: { id_type: '', userId: localStorage.userId },
      ListePiece: null,
    }
  },
  methods: {
    getListePiece() {
      service.getLists('idtype')
        .then(res => {
          var piecesIdentite = res.data;
          this.ListePiece = piecesIdentite.filter(f => f.code != "PDC");  // Exclude Permis de conduire       
        })
    },
    checkForm() {
      // if(!this.info.photo){
      //   this.$swal({ 
      //       icon:'error', 
      //       text: 'La photo de profil est requise' 
      //   });
      //   return false
      // }
      // else 
      if (!this.info.driver_driving_licence_front) {
        this.$swal({
          icon: 'error',
          text: 'La photo recto du permis de conduire est requise'
        });
        return false
      }
      else if (!this.info.driver_driving_licence_back) {
        this.$swal({
          icon: 'error',
          text: 'La photo verso du permis de conduire est requise'
        });
        return false
      }
      else {
        return true
      }
    },
    submit(e) {
      e.preventDefault();
      const userId = localStorage.userId;

      if (this.checkForm() == true) {

        this.$store.state.loadingActive = true;

        if (userId && userId != "") {
          service.updateDriverInfoData(userId, this.info)
            .then(res => {
              this.$store.state.loadingActive = false;
              if (res.status == 200) {

                if (!this.noNextPage) {
                  this.$router.push({ path: '/register/car' })
                } else {
                  window.location.reload();
                }

              }
            })
            .catch(err => {
              this.$store.state.loadingActive = false;
              console.log(err);
            })

        } else {
          this.$store.state.loadingActive = false;
          this.$router.push({ path: '/register' })
        }
      }

    }
  },
  mounted() {
    this.getListePiece();
  }
}
</script>

<style lang="scss" scoped></style>