<template>
    <div>
        <Header />
        <div id="accountBanner">
            <div class="overlay">
                <sui-container v-if="this.userData">
                    <h2>Bienvenue, {{ this.userData.full_name }}</h2>
                    <p>&nbsp;</p>
                    <!-- <h4 class="account-status">
                            Abonnement actif jusqu'au 20/20/2029
                        </h4> -->
                    <!-- <h4 class="status">
                            <label>Statut</label> 
                            <p v-if="this.userData.is_approved == false">En attente de validation</p>
                            <p v-if="this.userData.is_approved == true">Compte validé</p>
                        </h4> -->
                    <sui-button size="tiny" @click="logout" inverted icon="logout">Déconnexion</sui-button>
                </sui-container>
            </div>
        </div>
        <div class="section" id="accountDetails" v-if="this.userData">
            <sui-container>
                <sui-accordion exclusive fluid>

                    <sui-accordion-title>
                        <sui-icon name="dropdown" />
                        Votre compte
                    </sui-accordion-title>
                    <sui-accordion-content>

                        <sui-grid :columns="3" stackable>
                            <sui-grid-row>
                                <sui-grid-column>
                                    <AccountItem label="Email" :value="this.userData.email" />
                                </sui-grid-column>
                                <sui-grid-column>
                                    <AccountItem label="Nom Complet" :value="this.userData.full_name" />
                                </sui-grid-column>
                                <sui-grid-column>
                                    <AccountItem label="Genre" :value="this.userData.gender == 'F' ? 'Femme' : 'Homme'" />
                                </sui-grid-column>
                            </sui-grid-row>
                        </sui-grid>


                    </sui-accordion-content>

                    <sui-accordion-title>
                        <sui-icon name="dropdown" />
                        Informations personnelles
                    </sui-accordion-title>
                    <sui-accordion-content>

                        <!-- <div v-if="!this.userData.info">
                             <InfosReg no-title no-next-page />
                         </div> -->

                        <!-- <div v-else> -->
                        <sui-grid stackable :columns="3">
                            <sui-grid-column>
                                <AccountItem :edit="true" list="country" ctx="driver" label="Pays" ctxField="country"
                                    :value="this.userData.country" />
                            </sui-grid-column>
                            <sui-grid-column>
                                <AccountItem :edit="true" label="Ville" ctx="driver" ctxField="city"
                                    :value="this.userData.city" />
                            </sui-grid-column>
                            <sui-grid-column>
                                <AccountItem label="Téléphone" :value="this.userData.phone" />
                            </sui-grid-column>
                            <sui-grid-column>
                                <AccountItem :edit="true" list="idtype" ctx="info" ctxField="id_type" label="Type de pièce"
                                    :value="this.userData.info.id_type" />
                            </sui-grid-column>
                            <sui-grid-column>
                                <AccountItem :edit="true" ctx="info" ctxField="id_number" label="Numéro de pièce"
                                    :value="this.userData.info.id_number" />
                            </sui-grid-column>
                            <!-- <sui-grid-column>
                                         <AccountItem label="Code parrain" :value="this.userData.code_parrain" />
                                     </sui-grid-column> -->
                            <sui-grid-column>
                                <AccountItem :edit="true" ctx="info" ctxField="photo" class="avatar" type="image"
                                    label="Photo de profil" imageType="avatar" :value="this.userData.info.photo" />
                            </sui-grid-column>
                        </sui-grid>
                        <!-- </div> -->

                    </sui-accordion-content>

                    <sui-accordion-title>
                        <sui-icon name="dropdown" />
                        Documents du conducteur
                    </sui-accordion-title>
                    <sui-accordion-content>

                        <div v-if="!this.userData">
                            <DriverDocuments no-title no-next-page />
                        </div>
                        <div v-else>
                            <sui-grid stackable :columns="3">
                                <sui-grid-column v-if="this.userData.is_fulltime != null">
                                    <AccountItem :edit="true" ctx="driver" ctxField="is_fulltime" type='bool'
                                        label="Conducteur à plein temps" :value="this.userData.is_fulltime.toString()" />
                                </sui-grid-column>
                                <sui-grid-column>
                                    <AccountItem :edit="true" ctx="info" ctxField="driver_driving_licence_id"
                                        label="Numéro de permis de conduire"
                                        :value="this.userData.info.driver_driving_licence_id" />
                                </sui-grid-column>
                                <sui-grid-column>
                                    <AccountItem id="driving_licence_front" :edit="true" ctx="info"
                                        ctxField="driver_driving_licence_front" type="image"
                                        label="Permis de conduire recto" imageType="docs"
                                        :value="this.userData.info.driver_driving_licence_front" />
                                </sui-grid-column>
                                <sui-grid-column>
                                    <AccountItem id="driving_licence_back" :edit="true" ctx="info"
                                        ctxField="driver_driving_licence_back" type="image" label="Permis de conduire verso"
                                        imageType="docs" :value="this.userData.info.driver_driving_licence_back" />
                                </sui-grid-column>
                            </sui-grid>
                        </div>

                    </sui-accordion-content>

                    <sui-accordion-title>
                        <sui-icon name="dropdown" />
                        Véhicule
                    </sui-accordion-title>
                    <sui-accordion-content>

                        <!-- <div v-if="newCar">
                            <CarReg no-title no-next-page :data="this.carData" />
                            <sui-button style="margin-top: .4em" size="big" type="submit" fluid secondary @click="newCar = false">
                                ANNULER
                            </sui-button>
                        </div> -->

                        <sui-grid v-if="!newCar" :columns="3" stackable>
                            <!-- <sui-grid-row>
                                <sui-grid-column>
                                    <sui-button size="big" primary icon="add" @click="newCar = true" >
                                        Ajouter un véhicule
                                    </sui-button>
                                </sui-grid-column>
                            </sui-grid-row> -->

                            <sui-grid-column v-if="this.userData.default_car.mark != null">
                                <AccountItem :edit="true" label="Marque" list="carbrand" ctx="car" ctxField="mark"
                                    :value="this.userData.default_car.mark" :ctxId="this.userData.default_car.id" />
                            </sui-grid-column>
                            <sui-grid-column>
                                <AccountItem :edit="true" label="Modèle" ctx="car" ctxField="model"
                                    :value="this.userData.default_car.model" :ctxId="this.userData.default_car.id" />
                            </sui-grid-column>
                            <sui-grid-column v-if="this.userData.default_car.air_conditioner != null">
                                <AccountItem :edit="true" ctx="car" ctxField="air_conditioner"
                                    :ctxId="this.userData.default_car.id" type="bool" label="Climatisation"
                                    :value="this.userData.default_car.air_conditioner" />
                            </sui-grid-column>
                            <sui-grid-column v-if="this.userData.default_car.color != null">
                                <AccountItem list="carcolor" :edit="true" ctx="car" ctxField="color"
                                    :ctxId="this.userData.default_car.id" label="Couleur"
                                    :value="this.userData.default_car.color" />
                            </sui-grid-column>
                            <sui-grid-column v-if="this.userData.default_car.id_chassis != null">
                                <AccountItem :edit="true" ctx="car" ctxField="id_chassis"
                                    :ctxId="this.userData.default_car.id" label="Nnuméro de Chassis"
                                    :value="this.userData.default_car.id_chassis" />
                            </sui-grid-column>
                            <sui-grid-column v-if="this.userData.default_car.id_matricule != null">
                                <AccountItem :edit="true" ctx="car" ctxField="id_matricule"
                                    :ctxId="this.userData.default_car.id" label="Immatriculation"
                                    :value="this.userData.default_car.id_matricule" />
                            </sui-grid-column>
                            <sui-grid-column v-if="this.userData.default_car.year != null">
                                <AccountItem :edit="true" ctx="car" :ctxId="this.userData.default_car.id" label="Année"
                                    type="caryear" ctxField="year" :value="this.userData.default_car.year" />
                            </sui-grid-column>
                            <sui-grid-column v-if="this.userData.default_car.total_seats != null">
                                <AccountItem :edit="true" ctx="car" ctxField="total_seats"
                                    :ctxId="this.userData.default_car.id" label="Nombre de places"
                                    :value="this.userData.default_car.total_seats" />
                            </sui-grid-column>
                            <sui-grid-column v-if="this.userData.default_car.car_card != null">
                                <AccountItem id="driver_car_card" :edit="true" ctx="car" ctxField="car_card"
                                    :ctxId="this.userData.default_car.id" type="image" label="Photo de la carte grise"
                                    imageType="cars" :value="this.userData.default_car.car_card" />
                            </sui-grid-column>

                            <sui-grid-row v-if="this.userData.cars">
                                <!-- <sui-grid-column v-for="c in userData.cars" :key="c.id">
                                    <Car 
                                    :default=" c.id == userData.default_carId ? 'true' : null " 
                                    :data="c" 
                                    @edit="editCar"
                                    />
                                </sui-grid-column> -->
                            </sui-grid-row>
                        </sui-grid>



                    </sui-accordion-content>

                </sui-accordion>
            </sui-container>
        </div>


    </div>
</template>

<script>

import Header from "../../components/Header.vue";
import AccountItem from '../../components/AccountItem.vue';
import service from "../../providers/service";
// import Car from "../../components/Car";
// import InfosReg from "../register/infos";
// import CarReg from "../register/car";

export default {
    name: 'account',
    metaInfo: {
        title: 'Compte',
    },
    data() {
        return {
            userData: null,
            newCar: false,
            carData: null,
            // info: {},
            // driver: {},
            // car: {}
        }
    },
    components: {
        Header,
        AccountItem,
        // Car,
        // InfosReg,
        // CarReg
    },
    methods: {
        editCar(value) {
            this.newCar = true;
            this.carData = value;
        },
        logout() {
            service.logoutCustomer()
                .then(() => {
                    localStorage.clear();
                    this.$router.push({ path: '/' });
                });
        },
        loaduserData() {
            this.$store.state.loadingActive = true;
            service.getUserData(localStorage.userId)
                .then(res => {
                    this.$store.state.loadingActive = false;
                    // console.log(res);
                    if (res.status == 200) {

                        if (res.data.info == null) {
                            this.$router.push({ path: '/register/infos' });
                        }
                        else if (res.data.default_car == null) {
                            this.$router.push({ path: '/register/car' });
                        }
                        else {
                            this.userData = res.data;
                        }
                    }
                })
                .catch(() => {
                    this.$store.state.loadingActive = false;
                    this.$router.push({ path: '/' });
                })
        }
    },
    mounted() {

        this.loaduserData();

    },
}

</script>

<style lang="scss" scoped>
#accountBanner {
    background: url('../../assets/images/account_banner.png');
    background-position: center 33%;
    background-size: cover;

    .overlay {
        background: rgba($color: #043079, $alpha: .7);
        padding: 5em 0;

        .status {
            margin-top: 1em;

            p {
                color: #3fa9f5
            }
        }

        h2 {
            color: #FFF;
            margin-bottom: 0;
            padding-bottom: 0;

            @media screen and (max-width: 600px) {
                font-size: 1.1rem;
            }
        }

        h4 {
            margin-top: .5em;
            color: rgba($color: #FFF, $alpha: .5);

        }

    }

}

#accountDetails {
    text-align: left;

    .accordion {

        padding: 0 2em;

        .title {
            font-size: 2rem;
            margin-bottom: 0em;
            padding: 1em 0;
            border-bottom: 1px solid #dedede;

            @media screen and (max-width: 600px) {
                font-size: 1.5rem;
            }
        }

        .content {
            padding: 2em 2em;
            background: #e8e8e8ee;
            border-radius: .7em;
        }

        &:last-child {
            .title {
                border-bottom: none !important;
            }
        }

    }
}

@media screen and (max-width: 600px) {
    #accountDetails {
        padding: 2em 0;

        .accordion {
            padding: 0;

            .content {
                padding: 0;
            }
        }
    }
}
</style>