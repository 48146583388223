<template>
    <div class="accountItem">


        <label>{{ this.label }}</label>
        <img :src="this.value" v-if="this.type == 'image'" />
        <p v-else-if="this.type == 'bool'">
            <span v-if="this.value == 'true'">Oui</span>
            <span v-else>Non</span>
        </p>
        <p v-else>{{ this.value }}</p>

        <sui-button v-if="edit == true" id="editBtn" @click.native="editItem" size="mini" inverted
            primary>Modifier</sui-button>

        <sui-modal size="tiny" v-model="modalOpen">
            <sui-modal-header>{{ this.label }}</sui-modal-header>
            <sui-modal-content>
                <sui-modal-description>

                    <sui-form-field>

                        <select v-if="this.type === 'bool'" v-model="itemValue" class="ui selection dropdown" fluid>
                            <option :value="true">Oui</option>
                            <option :value="false">Non</option>
                        </select>
                        <YearSelect v-else-if="this.type == 'caryear'" v-model="itemValue" />
                        <fileInput v-else-if="this.type == 'image'" :id="this.id" v-model="itemValue" :name="this.userId"
                            :type="this.imageType" />
                        <select v-else-if="this.list" required class="ui selection dropdown" v-model="itemValue">
                            <option value="">Selectionner</option>
                            <option v-for="a in this.listData" :key="a.id" :value="a.value">{{ a.value }}</option>
                        </select>
                        <sui-input v-else fluid type="text" v-model="itemValue" :value="this.value"
                            :placeholder="this.label" />

                    </sui-form-field>

                </sui-modal-description>
            </sui-modal-content>
            <sui-modal-actions>
                <sui-button @click.native="closeModal">
                    Annuler
                </sui-button>
                <sui-button :loading="this.loading" :disabled="this.loading == true" @click.native="submitData" icon="check"
                    primary>
                    Valider
                </sui-button>
            </sui-modal-actions>
        </sui-modal>

    </div>
</template>

<script>
import service from "../providers/service";
import fileInput from "../components/fileInput";
import YearSelect from "./YearSelect.vue";

export default {
    name: 'accountItem',
    components: {
        fileInput,
        YearSelect
    },
    data() {
        return {
            modalOpen: false,
            itemValue: '',
            listData: null,
            userId: localStorage.userId,
            loading: false,
            data: {}
        }
    },
    props: [
        'label',
        'value',
        'type',
        'list',
        'edit',
        'ctx',
        'ctxId',
        'ctxField',
        'id',
        'imageType'
    ],
    mounted() {
        this.itemValue = this.value
    },
    methods: {
        editItem() {
            this.modalOpen = true;
            this.loading = false;

            if (this.list != null) {
                this.getListData();
            }

        },
        closeModal() {
            this.modalOpen = false;
        },
        getListData() {
            service.getLists(this.list)
                .then((res) => {
                    this.listData = res.data
                })
        },
        async submitData() {
            this.loading = true;
            this.data[this.ctxField] = this.itemValue

            var result;

            switch (this.ctx) {
                case "driver": {
                    result = await service.updateDriverData(this.userId, this.data)
                } break;
                case "info": {
                    result = await service.updateDriverInfoData(this.userId, this.data)
                } break;
                case "car": {
                    result = await service.updateDriverCarData(this.userId, this.ctxId, this.data)
                } break;
            }

            if (result.status == 200) {
                this.modalOpen = false;
                window.location.reload();
            }

        }

    },
}
</script>

<style lang="scss" scoped>
.accountItem {
    &:hover {
        cursor: pointer;
    }

    label {
        color: grey;
        font-size: small
    }

    img {
        display: block;
        height: 15em;
        width: auto;
        margin: .5em 0;
        object-fit: cover;
        border-radius: .7em
    }

    p {
        margin-bottom: .3em;
    }

    #editBtn {
        padding: 5px;
    }

    .modal {
        .header {
            font-size: 1em !important;
        }

        .description {
            .field * {
                width: 100%
            }
        }
    }
}
</style>