<template>
  <div class="car">

        <div class="ui two column stackable centered grid">
        <div class="column">
                
            <StepBar :total="3" :current="3" />

            <!-- {{car}} -->

                <sui-form @submit="this.submit">  

                    <sui-form-fields fields="two">
                        
                        <sui-form-field>
                            <label>Marque *</label>
                            <select required class="ui selection dropdown" v-model="car.mark">
                            <option value="">Selectionner</option>
                            <option v-for="m in carBrands" :key="m.id" >{{m.value}}</option>
                            </select>
                        </sui-form-field>

                        <sui-form-field>
                            <label>Modèle</label>
                            <sui-input v-model="car.model" type="text" placeholder="Model"/>
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-fields fields="two">

                        <sui-form-field>
                            <label>Couleur *</label>
                            <select required class="ui selection dropdown" v-model="car.color">
                            <option value="">Selectionner</option>
                            <option v-for="c in carColors" :key="c.id" >{{c.value}}</option>
                            </select>
                        </sui-form-field>

                        <sui-form-field>
                            <label>Année *</label>
                            <YearSelect required v-model="car.year" />
                        </sui-form-field>

                    </sui-form-fields>
                    
                    <sui-form-fields fields="two">

                        <sui-form-field>
                            <label>Numéro d'immatriculation *</label>
                            <sui-input required v-model="car.id_matricule" type="text" placeholder="Numéro de la plaque d'immatriculation"/><br>
                        </sui-form-field>

                        <sui-form-field>
                            <label>Véhicule climatisé</label>
                            <select class="ui selection dropdown" v-model="car.air_conditioner">
                                <option value="">Selectionner</option>
                                <option value="true">Oui</option>
                                <option value="false">Non</option>
                            </select>
                        </sui-form-field>
                    </sui-form-fields>

                        <sui-form-fields fields="two">

                        <sui-form-field>
                            <label>Nombre de place *</label>
                            <sui-input required v-model="car.total_seats" type="number" pattern="[0-9]*" placeholder="Model" min="4" max="60"/>
                        </sui-form-field>

                        <sui-form-field>
                            <label>Numéro de chassis *</label>
                            <sui-input required v-model="car.id_chassis" type="text" placeholder="Model"/>
                        </sui-form-field> 

                    </sui-form-fields>


                <h2>Documents du véhicule</h2>

                <sui-form-fields fields="two">

                    <sui-form-field>
                        <label>Photo de la carte grise (recto)</label>
                        <fileInput
                        id="car_carte_grise"
                        v-model="car.car_card" 
                        :name="this.userId"
                        type="cars" />
                    </sui-form-field>

                    <!-- <sui-form-field>
                        <label>Assurance</label>
                        <fileInput
                        id="insurance_card"
                        v-model="car.insurance_card" 
                        :name="this.userId"
                        type="cars" />
                    </sui-form-field>

                    <sui-form-field>
                        <label>Visite technique et vignette</label>
                        <fileInput
                        id="technical_review_card"
                        v-model="car.technical_review_card" 
                        :name="this.userId"
                        type="cars" />
                    </sui-form-field> -->

                    <!-- <sui-form-field>
                        <label>Photo du véhicule</label>
                        <fileInput
                        id="car_picture"
                        v-model="car.car_picture" 
                        :name="this.userId"
                        type="cars" />
                    </sui-form-field> -->

                </sui-form-fields>

                    <sui-form-field>
                        <sui-button size="big" type="submit" fluid primary>
                            VALIDER
                        </sui-button>
                    </sui-form-field>

                </sui-form>
                <!-- {{car}} -->
            </div>              
        </div>
  </div>
</template>

<script>
import fileInput from "../../components/fileInput";
import service from "../../providers/service";
import YearSelect from "../../components/YearSelect";
import StepBar from "../../components/StepBar";

export default {
name: 'car',
  components: {
      fileInput,
      YearSelect,
      StepBar
  },
  props:{
    noTitle : {
      type : Boolean
    },
    noNextPage :{
      type : Boolean
    },
    data : {
      type : Object
    }
  },
  data(){
      return{
          car : { air_conditioner: "", total_seats: 4 },
          carBrands : null,
          carColors : null,
          userId: localStorage.userId
      }
  },
  methods:{
      getCarMarks(){
        service.getLists('carbrand')
        .then( res => {  this.carBrands = res.data; })
      },
      getCarColors(){
        service.getLists('carcolor')
        .then( res => {  this.carColors = res.data; })
      },
      checkForm(){
        if(!this.car.car_card){
                this.$swal({ 
                    icon:'error', 
                    text: 'La photo de la carte grise est requise' 
                });
                return false
            }
            else{
                return true
            }
      },
      submit(e){
        e.preventDefault();
        const userId = localStorage.userId;

        if(this.checkForm() == true){

            this.$store.state.loadingActive = true;

            if(userId && userId != ""){
                service.createUserCar(userId, this.car)
                .then( res => {
                    this.$store.state.loadingActive = false;
                    if(res.status == 200){

                        if(!this.noNextPage){
                            this.$router.push({ path: '/register/complete' });
                        }else{
                            window.location.reload();
                        }

                    }
                })
                .catch( err => {
                    this.$store.state.loadingActive = false;
                    console.log(err);
                });
            }else{
                this.$router.push({ path: '/register' })
            }

        }


      }
  },
  mounted(){

      if(this.data != null){
          this.car = this.data;
          console.log(this.car);
      }

      this.getCarMarks();
      this.getCarColors();
  }

}
</script>

<style lang="scss" scoped>


</style>